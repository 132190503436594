import {
  GetAvailabilityResponseBody,
  GetAvailabilityResponseVariant,
} from '@purple-dot/main/src/presentation-layer/custom-server/api/views/get-availability-response';
import {
  GetWaitlistsResponseBody,
  GetWaitlistsResponseItem,
} from '@purple-dot/main/src/presentation-layer/custom-server/api/views/get-waitlists-response';
import {
  ShopifyApi,
  ShopifyProduct,
  ShopifyProductVariant,
} from './shopify-api';

import { getItem } from '@purple-dot/browser-sdk/src/libraries/local-storage';
import { apiFetch } from './api-fetch';
import { captureError } from './debug/capture-error';

/**
 * WaitlistAvailability
 *
 * Given a product identifier such as handle or ids, provide
 * an easy way to access whether there are available
 * waitlist spots for that product
 */

export interface VariantState {
  state: PreorderState;
  waitlist: GetWaitlistsResponseItem | null;
  product: ShopifyProduct | null;
}

export enum PreorderState {
  Available = 'AVAILABLE',
  Preorder = 'PRE_ORDER',
  SoldOut = 'SOLD_OUT',
  Unknown = 'UNKNOWN',
}

const _GET_MAP_TIMEOUT = 10000;

export class WaitlistAvailability {
  productMap!: any;
  waitlists!: GetWaitlistsResponseBody | null;
  shopifyApi!: ShopifyApi;
  enableWaitlists!: () => boolean | Promise<boolean>;

  constructor({
    shopifyApi,
    enableWaitlists,
  }: {
    shopifyApi: ShopifyApi;
    enableWaitlists?: () => boolean;
  }) {
    this.productMap = {};
    this.waitlists = null;
    this.shopifyApi = shopifyApi;
    this.enableWaitlists = enableWaitlists || (() => true);
  }

  async preload(handle?: string) {
    try {
      await this._fetchWaitlists();

      if (handle) {
        await this._fetchProduct(handle);
      }
    } finally {
      document.querySelector('#pd-preload-style')?.remove();
    }
  }

  async getWaitlist({
    handle,
    productId: passedId,
  }: {
    handle: string;
    productId?: number;
  }) {
    let productId: number;
    if (passedId) {
      productId = passedId;
    } else {
      const product = await this._fetchProduct(handle);
      productId = product.id;
    }

    const waitlist = await this._getWaitlistForProductId(productId);
    return waitlist;
  }

  async getVariantInventory(variantId: number) {
    const productAvailability = await this.fetchAvailability(variantId);

    const variantAvailability = productAvailability?.variants.find(
      (v) => v.id === `${variantId}`
    );

    return variantAvailability;
  }

  async getProductWaitlist(handle: string) {
    const product = await this._fetchProduct(handle);
    return {
      product,
      waitlist: await this._getWaitlistForProductId(product.id),
    };
  }

  async getProduct({
    handle,
    variantId,
  }: {
    handle?: string | null;
    variantId: number | null;
  }) {
    if (handle) {
      return await this._fetchProduct(handle);
    }

    if (variantId) {
      const availability = await this.fetchAvailability(variantId);
      if (availability?.product_code) {
        return await this._fetchProduct(availability.product_code);
      }
    }

    return null;
  }

  async _getWaitlistForProductId(productId: number) {
    const waitlists = await this._fetchWaitlists();

    if (!waitlists) {
      return null;
    }

    return waitlists.find((w) => w.product.id === `${productId}`) ?? null;
  }

  async fetchAvailability(
    variantId: number
  ): Promise<GetAvailabilityResponseBody | undefined> {
    const url = new URL(
      `${window.PurpleDotConfig.hostURL}/api/v1/availability`
    );
    if (window.PurpleDotConfig.apiKey) {
      url.searchParams.set('api_key', window.PurpleDotConfig.apiKey);
    }
    if (window.Shopify.shop) {
      url.searchParams.set('shop', window.Shopify.shop);
    }
    url.searchParams.set('variant_id', variantId.toString());
    const resp = await apiFetch(url);

    if (resp.ok) {
      const body = await resp.json();
      return body.data;
    }

    return undefined;
  }

  async _fetchProduct(handle: string): Promise<ShopifyProduct> {
    if (this.productMap[handle]) {
      return this.productMap[handle];
    }

    const product = await this.shopifyApi.fetchProduct(handle);
    this.productMap[handle] = product;
    return product;
  }

  async isPdSellingPlan(sellingPlanId: number) {
    const waitlists = await this._fetchWaitlists();

    if (!waitlists) {
      return false;
    }

    return waitlists.some(
      (w) =>
        w.selling_plan_id &&
        getSellingPlanId(w.selling_plan_id) === sellingPlanId
    );
  }

  async _fetchWaitlists(): Promise<GetWaitlistsResponseBody | null> {
    if (!this.waitlists) {
      const url = new URL(`${window.PurpleDotConfig.hostURL}/api/v1/waitlists`);
      if (window.PurpleDotConfig.apiKey) {
        url.searchParams.set('api_key', window.PurpleDotConfig.apiKey);
      }
      if (window.Shopify.shop) {
        url.searchParams.set('shop', window.Shopify.shop);
      }
      const resp = await apiFetch(url);
      if (resp.ok) {
        const body = await resp.json();
        this.waitlists = body.data;
      }
    }
    return this.waitlists;
  }

  _variantWaitlist(
    variant: ShopifyProductVariant,
    waitlists: GetWaitlistsResponseBody,
    waitlistAvailability: GetAvailabilityResponseBody
  ): GetWaitlistsResponseItem | null {
    const variantWaitlistAvailability = waitlistAvailability.variants.find(
      (availableVariant: GetAvailabilityResponseVariant) =>
        `${availableVariant.id}` === `${variant.id}`
    );

    if (!variantWaitlistAvailability) {
      return null;
    }

    const waitlist = waitlists.find(
      (w) => w.id === variantWaitlistAvailability.waitlist_id
    );

    if (!variantWaitlistAvailability.available) {
      return null;
    }

    return waitlist || null;
  }

  public async waitlistsEnabled() {
    let loopIsActive = false;
    try {
      loopIsActive = !!window?.LoopOnstore?.isActive();
    } catch (_err) {}

    if (loopIsActive) {
      return false;
    }

    const overrideRestrictedMarkets = getItem('overrideRestrictedMarkets');
    if (overrideRestrictedMarkets) {
      return true;
    }

    try {
      return await this.enableWaitlists();
    } catch (err) {
      void captureError(err as Error);
      return false;
    }
  }
}

function getSellingPlanId(graphqlSellingPlanId: string) {
  const id = graphqlSellingPlanId.split('/').pop();

  if (!id) {
    throw new Error(
      `No selling plan id found from graphQL id ${graphqlSellingPlanId}`
    );
  }
  return Number.parseInt(id, 10);
}
