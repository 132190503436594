import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/views/get-waitlists-response';
import {
  hoursToMilliseconds,
  intervalToDuration,
  isAfter,
  isFuture,
} from 'date-fns';
import { translate } from '../localization';
import { ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { WaitlistAvailability } from '../waitlist-availability';
import { NewEndpointPreorderState } from './backend';
import { isLRG } from './is-lrg';
import { isRestDuvet } from './is-rest-duvet';
import { PreorderStateListener } from './preorder-add-to-cart-form';

class PreorderElements implements PreorderStateListener {
  atcForm: AddToCartForm;
  hideOnSoldOut: boolean;
  locale: string;

  constructor(
    atcForm: AddToCartForm,
    locale: string,
    private waitlistAvailability: WaitlistAvailability,
    { hideOnSoldOut = false }: { hideOnSoldOut?: boolean } = {}
  ) {
    this.atcForm = atcForm;
    this.locale = locale;
    this.hideOnSoldOut = hideOnSoldOut;
  }

  onMount() {
    return;
  }

  onPreorder(
    _product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem,
    _variantId: number,
    atcForm: AddToCartForm
  ) {
    const addToCartButton = this.atcForm.getAddToCartButton();
    if (addToCartButton) {
      if (
        waitlist.is_scheduled &&
        waitlist.launch_date &&
        isFuture(new Date(waitlist.launch_date))
      ) {
        addToCartButton.disable();
        addToCartButton.changeText(
          translate('coming-soon-cta', this.locale),
          true
        );

        if (isLRG()) {
          const qtyElem = atcForm.querySelector('.product__qty');
          if (qtyElem) {
            qtyElem.style.display = 'none';
          }
        }
      } else {
        addToCartButton.enable();

        addToCartButton.changeText(
          translate(
            waitlist.use_extended_delivery_language
              ? 'extended-delivery-cta'
              : 'pre-order-cta',
            this.locale
          ),
          true
        );

        if (isLRG()) {
          const qtyElem = atcForm.querySelector('.product__qty');
          if (qtyElem) {
            qtyElem.style.display = 'auto';
          }
        }
      }
    }

    this._showWaitlistInfo(waitlist, atcForm);
    this._updateAddToCartFormClasses(waitlist, 'preorder');
  }

  inStock(
    _product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem | null,
    _variantId: number,
    atcForm: AddToCartForm
  ) {
    const addToCartButton = this.atcForm.getAddToCartButton();
    if (addToCartButton) {
      addToCartButton.enable();
      addToCartButton.changeText(translate('add-to-cart', this.locale));
    }

    this._hideWaitlistInfo(atcForm);
    this._updateAddToCartFormClasses(waitlist, 'instock');
  }

  soldOut(
    _product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem | null,
    _variantId: number,
    atcForm: AddToCartForm
  ) {
    const addToCartButton = this.atcForm.getAddToCartButton();
    if (addToCartButton) {
      const el = addToCartButton.getElement();
      if (this.hideOnSoldOut) {
        el.style.display = 'none';
      }

      addToCartButton.disable();
      addToCartButton.changeText(translate('sold-out', this.locale));
    }

    this._hideWaitlistInfo(atcForm);
    this._updateAddToCartFormClasses(waitlist, 'soldout');
  }

  unknownState() {
    this.atcForm.getAddToCartButton()?.disconnect();
  }

  noVariantSelected(
    _product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem | null,
    atcForm: AddToCartForm,
    state: NewEndpointPreorderState | undefined
  ) {
    if (isRestDuvet()) {
      const addToCartButton = this.atcForm.getAddToCartButton();
      addToCartButton?.changeText(translate('sold-out', this.locale), true);
      addToCartButton?.disable();
      this._hideWaitlistInfo(atcForm);
      return;
    }

    if (waitlist && state === NewEndpointPreorderState.OnPreorder) {
      const addToCartButton = this.atcForm.getAddToCartButton();
      addToCartButton?.changeText(
        translate(
          waitlist.use_extended_delivery_language
            ? 'extended-delivery-cta'
            : 'pre-order-cta',
          this.locale
        ),
        true
      );

      this._showWaitlistInfo(waitlist, atcForm);
    }
  }

  _updateAddToCartFormClasses(
    waitlist: GetWaitlistsResponseItem | null,
    modifier: string
  ) {
    const atcBlockClass = 'x-pd-atc-form';
    const atcFormEl = this.atcForm.getElement();

    const toRemove = Array.from(atcFormEl.classList).filter((name) =>
      name.startsWith(atcBlockClass)
    );
    atcFormEl.classList.remove(...toRemove);

    if (!waitlist) {
      return;
    }

    atcFormEl.classList.add(atcBlockClass, `${atcBlockClass}--${modifier}`);
  }

  _showWaitlistInfo(
    waitlist: GetWaitlistsResponseItem,
    atcForm: AddToCartForm
  ) {
    let dispatchDateElem = atcForm.querySelector('#pd-dispatch-date');
    if (!dispatchDateElem) {
      dispatchDateElem = document.createElement('p');
      dispatchDateElem.id = 'pd-dispatch-date';
    }

    if (waitlist.display_dispatch_date) {
      dispatchDateElem.innerHTML = waitlist.display_dispatch_date;
    }

    let paymentPlanElem = atcForm.querySelector('#pd-payment-plan-description');
    if (!paymentPlanElem) {
      paymentPlanElem = document.createElement('p');
      paymentPlanElem.id = 'pd-payment-plan-description';
    }

    if (waitlist.payment_plan_descriptions?.long) {
      paymentPlanElem.innerHTML = waitlist.payment_plan_descriptions.long;
    }

    let brandingElem = atcForm.querySelector('#pd-branding');
    if (!brandingElem) {
      brandingElem = document.createElement('purple-dot-learn-more');
      brandingElem.id = 'pd-branding';
    }

    if (waitlist.use_extended_delivery_language) {
      brandingElem.dataset.useExtendedDeliveryLanguage = 'true';
    }

    let rootElem = atcForm.querySelector('#pd-waitlist-info');
    if (!rootElem) {
      // This styling ensures that the spacing between the dispatchDate element
      // and the branding element fits in with the rest of the add-to-cart form
      // We assume here that branding elem is shown after the dispatch date elem
      const stylingElem = document.createElement('style');
      stylingElem.id = 'pd-waitlist-info-styling';
      stylingElem.innerHTML = `
        #pd-waitlist-info {
          margin: 1rem 0;
          padding: 0;
          font-size: initial;
        }
        #pd-waitlist-info p {
          margin: .5rem 0;
          padding: 0;
        }

        .pd-countdown-timer__time {
          position: relative;
          display: inline-flex;
          margin-right: 11px;
        }

        .pd-countdown-timer__time:after {
          content: ":";
          display: block;
          position: absolute;
          top: 3px;
          right: -6px;
          color: #fff;
        }

        .pd-countdown-timer__time:last-child:after {
          display: none;
        }

        .pd-countdown-timer__time span {
          display: block;
          background: #fff;
          color: #000005;
          margin-right: 3px;
          padding: 5px 7px;
          border-radius: 2px;
        }
      `;
      document.head.appendChild(stylingElem);

      rootElem = document.createElement('div');
      rootElem.id = 'pd-waitlist-info';

      if (
        isLRG() &&
        waitlist.is_scheduled &&
        waitlist.launch_date &&
        isFuture(new Date(waitlist.launch_date))
      ) {
        addComingSoonStatus(this.atcForm, waitlist);
      }

      rootElem.appendChild(dispatchDateElem);

      if (waitlist.payment_plan_descriptions?.long) {
        rootElem.appendChild(paymentPlanElem);
      }

      rootElem.appendChild(brandingElem);

      const atcElement = atcForm.getAddToCartButton()?.getElement();
      atcElement?.parentElement?.insertBefore(rootElem, atcElement.nextSibling);
    }

    rootElem.style.display = 'block';
  }

  _hideWaitlistInfo(atcForm: AddToCartForm) {
    const rootElem = atcForm.querySelector('#pd-waitlist-info');
    if (rootElem) {
      rootElem.style.display = 'none';
    }

    const countdownStatus = document.querySelector('#pd-coming-soon-status');
    countdownStatus?.remove();
  }
}

function addComingSoonStatus(
  atcForm: AddToCartForm,
  waitlist: GetWaitlistsResponseItem
) {
  if (!waitlist.launch_date) {
    return;
  }

  const container = document.createElement('div');
  container.id = 'pd-coming-soon-status';

  const usDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
    timeZone: 'America/New_York', // Set the timezone to Eastern time
  });

  const formattedDate = usDate.format(new Date(waitlist.launch_date));

  const textElement = document.createElement('p');
  textElement.innerHTML = `This item has not gone on sale yet. It will be available on ${formattedDate}`;
  textElement.style.textTransform = 'uppercase';
  textElement.style.letterSpacing = '3px';
  textElement.style.marginBottom = '1rem';
  container.appendChild(textElement);

  const countdownElement = addCountdownElement(new Date(waitlist.launch_date));
  countdownElement.style.marginBottom = '1rem';
  container.appendChild(countdownElement);

  const button = atcForm.getAddToCartButton()?.getElement();
  if (button) {
    button.parentElement?.parentElement?.insertBefore(
      container,
      button.parentElement
    );
  }
}

function addCountdownElement(targetDate: Date) {
  // Add the Timer
  const countdownElement = document.createElement('div');
  countdownElement.style.position = 'relative';
  countdownElement.style.display = 'flex';

  // Reload the page when we cross zero,
  // but only if we're within 24 hours of the target date to avoid overflow issues in setTimeout
  const timeout = targetDate.getTime() - Date.now();
  if (timeout > 0 && timeout < hoursToMilliseconds(24)) {
    setTimeout(() => window.location.reload(), timeout);
  }

  // Update the countdown every second
  setInterval(() => {
    const now = new Date();
    const duration = intervalToDuration({
      start: now,
      end: targetDate,
    });

    const timeComponents = isAfter(targetDate, now)
      ? [duration.days, duration.hours, duration.minutes, duration.seconds]
      : [0, 0, 0, 0];

    const elems = timeComponents.map((component) => {
      // Map each digit to a span
      const timeSpans = `${component ?? 0}`
        .padStart(2, '0')
        .split('')
        .map((digit) => {
          return `<span>${digit}</span>`;
        })
        .join('');

      return `<div class="pd-countdown-timer__time">${timeSpans}</div>`;
    });

    // Display the countdown in the element with id "countdown"
    countdownElement.innerHTML = elems.join('');
  }, 1000);

  return countdownElement;
}

export { PreorderElements };
