import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/views/get-waitlists-response';
import { PurpleDotConfig } from '../purple-dot-config';
import { PreorderStateListener } from '../purple-dot-integration/preorder-add-to-cart-form';
import { ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { ShopifyThemeListener } from '../shopify-theme/shopify-theme';
import { extractHandle } from '../shopify-theme/url-utils';
import { WaitlistAvailability } from '../waitlist-availability';
import { ButtonIframe } from './button-iframe';
import { NotifyMe } from './notify-me';
import { VariantSelectors } from './variant-selectors';

class SeparateBagThemeListener implements ShopifyThemeListener {
  integrationPoints: PurpleDotConfig;
  waitlistAvailability: WaitlistAvailability;

  constructor({
    integrationPoints,
    waitlistAvailability,
  }: {
    integrationPoints: PurpleDotConfig;
    waitlistAvailability: WaitlistAvailability;
  }) {
    this.integrationPoints = integrationPoints;
    this.waitlistAvailability = waitlistAvailability;
  }

  onPageLoaded() {
    const cartButtonIntegration = this.integrationPoints.cart;

    const cartLinks = document.querySelectorAll(
      this.integrationPoints.cart?.selector ?? 'a[href$="/cart"]'
    );
    cartLinks.forEach((cartLink, index) => {
      const cart = this._createCartElement({ instanceId: `${index}` });

      if (!cartButtonIntegration?.placeElement) {
        return;
      }

      const container = cartButtonIntegration.placeElement(cart, cartLink);
      container.classList.add(`pd-cart-button-container-${index}`);

      window.PurpleDot.load({
        placementType: 'cart-button',
        instanceId: `${index}`,
        style: {
          fill: this.integrationPoints.cart?.fill,
          strokeWidth: this.integrationPoints.cart?.strokeWidth,
          width: this.integrationPoints.cart?.width,
          height: this.integrationPoints.cart?.height,
          padding: this.integrationPoints.cart?.padding,
        },
      });

      if (cartButtonIntegration?.matchFill) {
        window.PurpleDot.matchCartButtonToElement({
          instanceId: `${index}`,
          element: cartButtonIntegration.matchFill,
          cssAttribute: 'color',
        });
      }
    });
  }

  _createCartElement({ instanceId }: { instanceId: string }) {
    const cart = document.createElement('a');
    cart.style.display = 'inline-flex';
    cart.style.verticalAlign = 'middle';
    cart.setAttribute('data-purple-dot-placement-type', 'cart-button');
    cart.setAttribute('data-purple-dot-instance-id', instanceId);
    cart.setAttribute('href', '/');
    return cart;
  }

  async onNewAddToCartForm(addToCartForm: AddToCartForm) {
    const handle = extractHandle(window.location.href);
    if (!handle) {
      return;
    }
    const waitlist = await this.waitlistAvailability.getWaitlist({
      handle,
    });

    if (!waitlist) {
      return;
    }

    const variantSelectors = new VariantSelectors({
      config: this.integrationPoints.pdp?.variantSelector,
      waitlist,
    });
    variantSelectors.enableSelectors(addToCartForm);
  }
}

class SeparateBagAddToCartForm implements PreorderStateListener {
  hostUrl!: string;
  integrationPoints!: PurpleDotConfig;
  notifyMe?: NotifyMe;
  onButtonClicked!: (() => void) | null;
  buttonIframe?: ButtonIframe;

  constructor({
    hostUrl,
    integrationPoints,
    enableNotifyMe,
  }: {
    hostUrl: string;
    integrationPoints: PurpleDotConfig;
    enableNotifyMe: boolean;
  }) {
    this.hostUrl = hostUrl;
    this.integrationPoints = integrationPoints;
    this.notifyMe = enableNotifyMe ? new NotifyMe() : undefined;
  }

  onMount() {
    return;
  }

  onPreorder(
    product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    variantId: number,
    addToCartForm: AddToCartForm
  ) {
    if (!this.buttonIframe) {
      this.buttonIframe = new ButtonIframe(this.hostUrl, addToCartForm.el);
    }

    this.buttonIframe.mount(product, variantId);

    if (this.notifyMe) {
      this.notifyMe.show(this.buttonIframe, addToCartForm);
    }

    const addToCartButton = addToCartForm.getAddToCartButton();
    if (addToCartButton) {
      const el = addToCartButton.getElement();
      el.type = 'button';

      // Try and make sure the button has the right event listener
      // This is a bit tricky because the button can be deleted entirely
      // by the theme when variant changes sometimes.
      // Our workaround for this is to always remove it and add it again.

      if (this.onButtonClicked) {
        el.removeEventListener('click', this.onButtonClicked);
      } else {
        this.onButtonClicked = () => {
          const quantityValue =
            addToCartForm.el.querySelector<HTMLInputElement>(
              'input[name="quantity"]'
            )?.value;
          let quantity = 1;
          if (quantityValue) quantity = Number.parseInt(quantityValue, 10);
          if (Number.isNaN(quantity)) quantity = 1;

          this.buttonIframe?.onButtonClicked({
            quantity,
          });
        };
      }

      el.addEventListener('click', this.onButtonClicked);
    }
  }

  inStock(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _variantId: number,
    addToCartForm: AddToCartForm
  ) {
    this._notOnPreorder(addToCartForm);
  }

  soldOut(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _variantId: number,
    addToCartForm: AddToCartForm
  ) {
    this._notOnPreorder(addToCartForm);
  }

  unknownState(_variantId: number, addToCartForm: AddToCartForm) {
    this._notOnPreorder(addToCartForm);
  }

  noVariantSelected() {
    return;
  }

  _notOnPreorder(addToCartForm: AddToCartForm) {
    if (this.notifyMe) {
      this.notifyMe.hide();
    }

    const atcButton = addToCartForm.getAddToCartButton();
    if (atcButton) {
      const el = atcButton.getElement();

      if (el) {
        el.type = 'submit';

        if (this.onButtonClicked) {
          el.removeEventListener('click', this.onButtonClicked);
          this.onButtonClicked = null;
        }
      }
    }
  }
}

class SeparateBagCheckoutMethod {
  hostUrl!: string;
  integrationPoints!: PurpleDotConfig;
  waitlistAvailability!: WaitlistAvailability;
  enableNotifyMe!: boolean;

  constructor({
    hostUrl,
    integrationPoints,
    waitlistAvailability,
    enableNotifyMe,
  }: {
    hostUrl: string;
    integrationPoints: PurpleDotConfig;
    waitlistAvailability: WaitlistAvailability;
    enableNotifyMe: boolean;
  }) {
    this.hostUrl = hostUrl;
    this.integrationPoints = integrationPoints;
    this.waitlistAvailability = waitlistAvailability;
    this.enableNotifyMe = enableNotifyMe;
  }

  getThemeListener() {
    return new SeparateBagThemeListener({
      integrationPoints: this.integrationPoints,
      waitlistAvailability: this.waitlistAvailability,
    });
  }

  getAddToCartListener() {
    return new SeparateBagAddToCartForm({
      hostUrl: this.hostUrl,
      integrationPoints: this.integrationPoints,
      enableNotifyMe: this.enableNotifyMe,
    });
  }
}

export { SeparateBagCheckoutMethod };
