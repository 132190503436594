import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/views/get-waitlists-response';
import { PurpleDotConfig } from '../purple-dot-config';
import { PreorderStateListener } from '../purple-dot-integration/preorder-add-to-cart-form';
import { ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { ShopifyThemeListener } from '../shopify-theme/shopify-theme';
import { extractHandle } from '../shopify-theme/url-utils';
import { WaitlistAvailability } from '../waitlist-availability';
import { ButtonIframe } from './button-iframe';
import { NotifyMe } from './notify-me';
import { VariantSelectors } from './variant-selectors';

class ExpressThemeListener implements ShopifyThemeListener {
  integrationPoints: PurpleDotConfig;
  waitlistAvailability: WaitlistAvailability;

  constructor({
    integrationPoints,
    waitlistAvailability,
  }: {
    integrationPoints: PurpleDotConfig;
    waitlistAvailability: WaitlistAvailability;
  }) {
    this.integrationPoints = integrationPoints;
    this.waitlistAvailability = waitlistAvailability;
  }

  async onNewAddToCartForm(addToCartForm: AddToCartForm) {
    const handle = extractHandle(window.location.href);
    if (!handle) {
      return;
    }

    const waitlist = await this.waitlistAvailability.getWaitlist({ handle });

    const variantSelectors = new VariantSelectors({
      config: this.integrationPoints.pdp?.variantSelector,
      waitlist: waitlist || undefined,
    });
    variantSelectors.enableSelectors(addToCartForm);
  }
}

class ExpressAddToCartListener implements PreorderStateListener {
  hostUrl: string;
  notifyMe?: NotifyMe;
  onButtonClicked!: ((e: Event) => void) | null;
  buttonIframe?: ButtonIframe;

  constructor({
    hostUrl,
    enableNotifyMe,
  }: {
    hostUrl: string;
    enableNotifyMe?: boolean;
  }) {
    this.hostUrl = hostUrl;
    this.notifyMe = enableNotifyMe ? new NotifyMe() : undefined;
  }

  onMount() {
    return;
  }

  onPreorder(
    product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    variantId: number,
    addToCartForm: AddToCartForm
  ) {
    if (!this.buttonIframe) {
      this.buttonIframe = new ButtonIframe(this.hostUrl, addToCartForm.el);
    }

    this.buttonIframe.mount(product, variantId);

    if (this.notifyMe) {
      this.notifyMe.show(this.buttonIframe, addToCartForm);
    }

    const addToCartButton = addToCartForm.getAddToCartButton();
    if (addToCartButton) {
      const el = addToCartButton.getElement();
      el.type = 'button';

      if (!this.onButtonClicked) {
        this.onButtonClicked = (e: Event) => {
          e.preventDefault();
          e.stopImmediatePropagation();

          const quantityValue =
            addToCartForm.el.querySelector<HTMLInputElement>(
              'input[name="quantity"]'
            )?.value;
          let quantity = 1;
          if (quantityValue) quantity = Number.parseInt(quantityValue, 10);
          if (Number.isNaN(quantity)) quantity = 1;

          this.buttonIframe?.onButtonClicked({
            quantity,
          });
        };
      }

      // Ensure we only add the event listener once
      el.removeEventListener('click', this.onButtonClicked, { capture: true });
      el.addEventListener('click', this.onButtonClicked, { capture: true });
    }
  }

  inStock(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _variantId: number,
    addToCartForm: AddToCartForm
  ) {
    this.notOnPreorder(addToCartForm);
  }

  soldOut(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _variantId: number,
    addToCartForm: AddToCartForm
  ) {
    this.notOnPreorder(addToCartForm);
  }

  unknownState(_variantId: number, addToCartForm: AddToCartForm) {
    this.notOnPreorder(addToCartForm);
  }

  noVariantSelected() {
    return;
  }

  notOnPreorder(addToCartForm: AddToCartForm) {
    if (this.notifyMe) {
      this.notifyMe.hide();
    }
    const atcButton = addToCartForm.getAddToCartButton();
    if (atcButton) {
      const el = atcButton.getElement();

      if (el) {
        el.type = 'submit';

        if (this.onButtonClicked) {
          el.removeEventListener('click', this.onButtonClicked, {
            capture: true,
          });
          this.onButtonClicked = null;
        }
      }
    }
  }
}

class ExpressCheckoutMethod {
  hostUrl!: string;
  integrationPoints!: PurpleDotConfig;
  waitlistAvailability!: WaitlistAvailability;
  enableNotifyMe!: boolean;

  constructor({
    hostUrl,
    integrationPoints,
    waitlistAvailability,
    enableNotifyMe,
  }: {
    hostUrl: string;
    integrationPoints: PurpleDotConfig;
    waitlistAvailability: WaitlistAvailability;
    enableNotifyMe: boolean;
  }) {
    this.hostUrl = hostUrl;
    this.integrationPoints = integrationPoints;
    this.waitlistAvailability = waitlistAvailability;
    this.enableNotifyMe = enableNotifyMe;
  }

  getThemeListener() {
    return new ExpressThemeListener({
      integrationPoints: this.integrationPoints,
      waitlistAvailability: this.waitlistAvailability,
    });
  }

  getAddToCartListener() {
    return new ExpressAddToCartListener({
      hostUrl: this.hostUrl,
      enableNotifyMe: this.enableNotifyMe,
    });
  }
}

export { ExpressCheckoutMethod };
