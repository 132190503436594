/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import { captureError, logToPurpleDotApi } from '../../debug/capture-error';
import { NewEndpointPreorderState } from '../../purple-dot-integration/backend';
import { Interceptor } from './interceptor';

export class CheckoutNavigationInterceptor extends Interceptor<URL> {
  constructor() {
    super();

    if (window.location.pathname.includes('/checkouts/')) {
      if (
        window.PurpleDotConfig.checkoutLineItems?.some(
          (item) => item.preorder_state === NewEndpointPreorderState.OnPreorder
        )
      ) {
        redirectToCartPage();
      }

      this.listenForMutations(this.redirectIfPreorderCheckout);
      this.listenForMutations(this.logIfPurpleDotBlocksCheckout);
    }

    this.addClickListener();
  }

  private addClickListener() {
    window.addEventListener('click', (event: MouseEvent) => {
      if (
        event.target &&
        event.target instanceof HTMLAnchorElement &&
        !event.defaultPrevented
      ) {
        const linkHREF = event.target.href;
        const linkURL = new URL(linkHREF, window.location.href);

        if (linkURL.pathname.startsWith('/checkout')) {
          event.preventDefault();

          // This never resolves because we navigate away from the page.
          const onComplete = new Promise(() => undefined);

          try {
            this.chainHandlers(linkURL, onComplete)
              .then((newURL) => {
                window.location.href = newURL.toString();
              })
              .catch((error) => {
                void captureError(error);
                window.location.href = linkHREF;
              });
          } catch (error) {
            void captureError(error);
            window.location.href = linkHREF;
          }
        }
      }
    });
  }

  private listenForMutations(
    listener: (
      _mutationList?: MutationRecord[],
      observer?: MutationObserver
    ) => void
  ) {
    listener();

    const observer = new MutationObserver(listener);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  private logIfPurpleDotBlocksCheckout(
    this: void,
    _mutationList?: MutationRecord[],
    observer?: MutationObserver
  ) {
    if (
      hasMatchingElementByXPath(
        "//div[contains(., 'Oops! Given you have pre-order(s) in your cart, please click ‘Cart‘ above to continue with your checkout.')]"
      )
    ) {
      // biome-ignore lint/nursery/noConsole: log
      console.debug(
        'Detected checkout blocked by Purple Dot slipthrough prevention'
      );

      void logToPurpleDotApi({
        message: 'Checkout blocked by Purple Dot',
        preorderLineItemPropertiesDisplayed:
          preorderLineItemPropertiesDisplayed(),
      });

      if (observer) {
        observer.disconnect();
      }
    }
  }

  private redirectIfPreorderCheckout(this: void) {
    if (preorderLineItemPropertiesDisplayed()) {
      redirectToCartPage();
      return;
    }
  }
}

function redirectToCartPage() {
  void captureError(new Error('checkout slip-through'));

  const prefix = window.Shopify?.routes?.root || '/';
  window.location.pathname = `${prefix}cart`;
}

function preorderLineItemPropertiesDisplayed() {
  const lineItemProperties = document.querySelectorAll(
    '.product__description__property'
  );

  for (const lineItemProperty of lineItemProperties) {
    if (lineItemProperty.innerHTML.includes('Purple Dot Pre-order:')) {
      return true;
    }
  }

  return false;
}

function hasMatchingElementByXPath(
  xpath: string,
  parent: Node = document
): boolean {
  const query = document.evaluate(
    xpath,
    parent,
    null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
    null
  );

  return query.snapshotLength > 0;
}
