import { isLRG } from '../purple-dot-integration/is-lrg';
import { ElementText } from './element-text';
import { findDeepestElementsMatching } from './find-deepest-elements-matching';

/**
 * AddToCartButton
 *
 * Represents the button used to submit the AddToCartForm
 * Will almost always be a button[type="submit"] inside the form.
 *
 * Although these should be unique / standard inside a form they aren't always
 * and so this adapter provides a simpler interface to work with
 */

enum State {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

class AddToCartButton {
  el: HTMLButtonElement;
  state: State | null;
  text: string | null;
  keep: boolean;
  elementTexts: ElementText[];
  mutationObserver: MutationObserver;

  constructor({ el }: { el: HTMLButtonElement }) {
    this.el = el;
    this.state = null;
    this.text = null;
    this.keep = false;
    this.elementTexts = [];

    this.mutationObserver = new MutationObserver(() => {
      if (this.text && this.keep) {
        this._updateText();
      }
      this._updateDisabled();
    });
    this.mutationObserver.observe(this.el, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
    });
  }

  getElement() {
    return this.el;
  }

  changeText(text: string, keep: boolean | null = null) {
    this.text = text;

    // TODO: As a risk mitigation we only enable the mutation observer
    // which resets the text for preorders, and for Pulse because their theme breaks otherwise.
    // We should continue to roll this out more widely.
    this.keep = keep ?? window.location.hostname.includes('thepulseboutique');

    this._updateText();
  }

  _updateText() {
    if (!this.text) {
      return;
    }

    while (this.elementTexts.length) {
      this.elementTexts.pop()?.disconnect();
    }

    for (const el of this._findTextElements()) {
      const elementText = new ElementText(el);
      elementText.setText(this.text, this.keep);

      this.elementTexts.push(elementText);
    }
  }

  enable() {
    this.state = State.Enabled;
    this.el.classList.remove('disabled');

    // Sometimes buttons are hidden
    if (this.el.style.display === 'none') {
      this.el.style.display = '';
    }

    this._updateDisabled();
  }

  disable() {
    this.state = State.Disabled;
    this.el.classList.add('disabled');

    this._updateDisabled();
  }

  disconnect() {
    this.mutationObserver.disconnect();

    while (this.elementTexts.length) {
      this.elementTexts.pop()?.disconnect();
    }
  }

  _updateDisabled() {
    if (this.state === State.Disabled && !this.el.disabled) {
      this.el.disabled = true;

      if (isLRG()) {
        this.el.classList.add('btn--disabled');
        this.el.addEventListener('click', lrgClickOverride, true);
      }
    }

    if (this.state === State.Enabled && this.el.disabled) {
      this.el.disabled = false;

      if (isLRG()) {
        this.el.classList.remove('btn--disabled');
        this.el.removeEventListener('click', lrgClickOverride);
      }
    }
  }

  _findTextElements() {
    return findDeepestElementsMatching(this.el, (el) => {
      const elText = new ElementText(el);
      return (
        elText.hasText('add to shopping bag') ||
        elText.hasText('add to cart') ||
        elText.hasText('add to bag') ||
        elText.hasText('añadir a la cesta') ||
        elText.hasText('agregar al carrito') ||
        elText.hasText('in den warenkorb legen') ||
        elText.hasText('sold out') ||
        elText.hasText('out of stock') ||
        elText.hasText('coming soon') ||
        elText.hasText('agotado') ||
        elText.hasText('ausverkauft') ||
        elText.hasText('pre-order') ||
        elText.hasText('pre order') ||
        elText.hasText('add to cart - extended delivery') ||
        elText.hasText('resérvalo') ||
        elText.hasText('vorbestellung') ||
        elText.hasText('voorbestellen') ||
        elText.hasText('uitverkocht') ||
        elText.hasText('aan winkelwagen toevoegen') ||
        elText.hasText('notify me when available') ||
        elText.hasText('unavailable') ||
        elText.hasText('order now!') ||
        false
      );
    });
  }
}

function lrgClickOverride(ev: any) {
  ev.preventDefault();
  ev.stopPropagation();
}

export { AddToCartButton };
