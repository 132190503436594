/* eslint-disable no-underscore-dangle */

import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/views/get-waitlists-response';
import { VariantSelectorConfig } from '../purple-dot-config';
import {
  NewEndpointPreorderState,
  VariantPreorderState,
  fetchVariantsPreorderState,
} from '../purple-dot-integration/backend';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';

class VariantSelectors {
  config!: VariantSelectorConfig;
  waitlist?: GetWaitlistsResponseItem;
  mutationObserver!: MutationObserver;

  results: Record<string, VariantPreorderState | null> = {};

  constructor({
    config,
    waitlist,
  }: {
    config?: VariantSelectorConfig;
    waitlist?: GetWaitlistsResponseItem;
  } = {}) {
    if (!config) {
      throw new Error('VariantSelectors must be initialised with config');
    }
    this.config = config;
    this.waitlist = waitlist;
  }

  enableSelectors(addToCartForm: AddToCartForm) {
    const { find, selector } = this.config;
    const elems = find
      ? find(addToCartForm)
      : addToCartForm.querySelectorAll(selector);

    this._updateElems(elems);

    this.mutationObserver = new MutationObserver(() => {
      this._updateElems(elems);
    });

    elems.forEach((elem) => {
      this.mutationObserver.observe(elem, {
        attributes: true,
        subtree: true,
      });
    });
  }

  _updateElems(elems: NodeListOf<Element>) {
    const { disabledClass } = this.config;

    elems.forEach(async (elem) => {
      const variantId =
        Number.parseInt(elem.getAttribute('data-variant-id') || '') ||
        Number.parseInt(
          elem
            .querySelector('[data-variant-id]')
            ?.getAttribute('data-variant-id') || ''
        );
      const preorderState = variantId
        ? await this.fetchVariantState(variantId)
        : null;

      if (
        preorderState?.state === NewEndpointPreorderState.OnPreorder ||
        (!preorderState?.state && this.waitlist)
      ) {
        [elem, ...Array.from(elem.querySelectorAll('[disabled]'))].forEach(
          (el) => {
            if (el.hasAttribute('disabled')) {
              el.removeAttribute('disabled');
            }
            if (el.classList.contains(disabledClass)) {
              el.classList.remove(disabledClass);
            }
          }
        );
      } else if (preorderState?.state === NewEndpointPreorderState.SoldOut) {
        /*
          Sometimes we need to add/edit classes or attributes when an item is on a waitlist but sold out of PD stock.
          This is because we don't uncheck 'continue selling' when a waitlist sells out.
        */
        [
          elem,
          ...Array.from(elem.querySelectorAll('[data-available]')),
        ].forEach((el) => {
          if (el.getAttribute('data-available') === 'true') {
            el.setAttribute('data-available', 'false');
          }
        });
      }
    });
  }

  async fetchVariantState(variantId: number) {
    if (!this.results[variantId]) {
      this.results[variantId] = await fetchVariantsPreorderState(variantId);
    }

    return this.results[variantId];
  }
}

export { VariantSelectors };
