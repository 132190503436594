import { t } from '@purple-dot/translations/i18n';
import { createLearnMoreButton } from '@purple-dot/web-components/learn-more/learn-more-modal';
import { RemoveAttribute } from '../purple-dot-config';
import {
  SelectorObserver,
  SelectorObserverMode,
} from '../shopify-theme/selector-observer';
import { WaitlistAvailability } from '../waitlist-availability';
import {
  NewEndpointPreorderState,
  fetchProductsPreorderState,
} from './backend';
import { isLRG } from './is-lrg';

class QuickAddFromCollectionItem {
  waitlistAvailability!: WaitlistAvailability;
  plpHide?: string[];
  title?: string;
  newTitle?: string;
  sizeSelector?: string;
  buttonSelector?: string;
  disabledClass?: string;
  removeAttributes!: RemoveAttribute[];
  addWaitlistInfo: boolean | undefined;

  constructor({
    waitlistAvailability,
    plpHide,
    title,
    newTitle,
    sizeSelector,
    buttonSelector,
    disabledClass,
    removeAttributes,
    addWaitlistInfo,
  }: {
    waitlistAvailability: WaitlistAvailability;
    plpHide?: string[];
    title?: string;
    newTitle?: string;
    sizeSelector?: string;
    buttonSelector?: string;
    disabledClass?: string;
    removeAttributes?: RemoveAttribute[];
    addWaitlistInfo?: boolean;
  }) {
    this.waitlistAvailability = waitlistAvailability;
    this.plpHide = plpHide;
    this.title = title;
    this.newTitle = newTitle;
    this.sizeSelector = sizeSelector;
    this.buttonSelector = buttonSelector;
    this.disabledClass = disabledClass;
    this.removeAttributes = removeAttributes || [];
    this.addWaitlistInfo = addWaitlistInfo;
  }

  async onNewCollectionItem({
    gridItem,
    handle,
    href,
  }: {
    gridItem: HTMLElement;
    handle: string;
    href: string;
  }) {
    try {
      const waitlistsEnabled =
        await this.waitlistAvailability.waitlistsEnabled();
      const preorderState = await fetchProductsPreorderState(handle);

      if (!waitlistsEnabled) {
        this._replaceQuickAddWithLinks(gridItem, href, false, {
          replaceButtonText: false,
        });
      }

      if (
        preorderState?.state === NewEndpointPreorderState.OnPreorder &&
        waitlistsEnabled &&
        preorderState.waitlist
      ) {
        this._handleQuickAddOnCollectionItem(
          gridItem,
          href,
          preorderState.waitlist.display_dispatch_date,
          preorderState.waitlist.is_scheduled,
          preorderState.waitlist.use_extended_delivery_language
        );
      }
    } catch (err) {
      // biome-ignore lint/nursery/noConsole: log
      console.error(err);
    }
  }

  _handleQuickAddOnCollectionItem(
    gridItem: HTMLElement,
    href: string,
    displayDispatchDate: string,
    isScheduled: boolean,
    useExtendedDeliveryLanguage: boolean
  ) {
    if (isLRG()) {
      if (isScheduled) {
        const span = document.createElement('span');
        span.classList.add('tag');
        span.classList.add('tag--soon');
        span.innerHTML = 'Coming soon';

        gridItem.appendChild(span);
      }
    }

    this._replaceTitle(gridItem, isScheduled, useExtendedDeliveryLanguage);
    this._replaceSoldOutBadges(
      gridItem,
      isScheduled,
      useExtendedDeliveryLanguage
    );
    this._replaceNotifyMeButtons(
      gridItem,
      href,
      isScheduled,
      useExtendedDeliveryLanguage
    );
    this._replaceQuickAddWithLinks(gridItem, href, useExtendedDeliveryLanguage);
    this._removeSomeAttributes(gridItem);
    this._hideElements(gridItem);

    if (this.addWaitlistInfo) {
      this._addWaitlistInfo(gridItem, displayDispatchDate);
    }
  }

  _getTitleText(isScheduled: boolean, useExtendedDeliveryLanguage: boolean) {
    if (isScheduled) {
      return t('shopify:shopify-app.coming-soon-cta').toString();
    }
    return (
      this.newTitle ||
      t(
        `shopify:shopify-app.${
          useExtendedDeliveryLanguage
            ? 'extended-delivery-cta'
            : 'pre-order-cta'
        }`
      ).toString()
    );
  }

  _replaceTitle(
    gridItem: HTMLElement,
    isScheduled: boolean,
    useExtendedDeliveryLanguage: boolean
  ) {
    if (this.title) {
      const titleEl = gridItem.querySelector(this.title);
      if (titleEl) {
        titleEl.innerHTML = this._getTitleText(
          isScheduled,
          useExtendedDeliveryLanguage
        );
      }
    }
  }

  _replaceSoldOutBadges(
    gridItem: HTMLElement,
    isScheduled: boolean,
    useExtendedDeliveryLanguage
  ) {
    const soldOutBadges = this._getSoldOutBadges(gridItem);
    soldOutBadges.forEach((badge) => {
      badge.innerHTML = this._getTitleText(
        isScheduled,
        useExtendedDeliveryLanguage
      );
      badge.classList.add('pd__pre-order-badge');
    });
  }

  _replaceNotifyMeButtons(
    gridItem: HTMLElement,
    href: string,
    isScheduled: boolean,
    useExtendedDeliveryLanguage: boolean
  ) {
    const notifyMeButtons = this._getNotifyMeButtons(gridItem);
    notifyMeButtons.forEach((button) => {
      const clone = button.cloneNode(true) as HTMLButtonElement;
      clone.innerHTML = this._getTitleText(
        isScheduled,
        useExtendedDeliveryLanguage
      );
      clone.onclick = () => {
        window.location.href = href;
      };

      button.parentElement?.appendChild(clone);
      button.style.display = 'none';
    });
  }

  _replaceQuickAddWithLinks(
    gridItem: HTMLElement,
    href: string,
    useExtendedDeliveryLanguage: boolean,
    opts?: { replaceButtonText: boolean }
  ) {
    const gridItemObserver = new SelectorObserver({
      selector: `${this.sizeSelector}, ${this.buttonSelector}`,
      container: gridItem,
      mode: SelectorObserverMode.ON_SHOWN,
    });

    gridItemObserver.listen((elem) => {
      /*
        Element may be a custom tag that behaves like a button
        e.g we have seen elements that are <add-to-cart>. For simplicity
        we are just pretending that it is a button here
      */
      this._replaceQuickAddWithLinksForButton(
        elem as HTMLButtonElement,
        href,
        useExtendedDeliveryLanguage,
        opts
      );
    });
  }

  _replaceQuickAddWithLinksForButton(
    button: HTMLButtonElement,
    href: string,
    useExtendedDeliveryLanguage: boolean,
    opts = {
      replaceButtonText: true,
    }
  ) {
    if (button.classList.contains('link-added')) {
      return;
    }
    button.disabled = false;
    if (this.disabledClass) {
      button.classList.remove(this.disabledClass);
    }

    const link = button.cloneNode(true) as HTMLButtonElement;

    link.onclick = (e) => {
      e.preventDefault();
      const { variantId } = button.dataset;
      const url = new URL(href);
      if (variantId) {
        url.searchParams.append('variant', variantId);
      }
      window.location.href = url.href;
    };

    if (link.type === 'submit') {
      link.type = 'button';
    }

    link.classList.add('link-added');

    button.parentElement?.insertBefore(link, button);
    button.parentElement?.removeChild(button);

    if (
      (link.innerHTML.toLowerCase() === t('shopify:shopify-app.add-to-cart') ||
        link.innerHTML.toLowerCase() === 'add to bag' ||
        link.innerHTML.toLowerCase() === 'add to cart' ||
        link.innerHTML.toLowerCase() === 'add') &&
      opts.replaceButtonText
    ) {
      link.innerHTML = t(
        `shopify:shopify-app.${
          useExtendedDeliveryLanguage
            ? 'extended-delivery-cta'
            : 'pre-order-cta'
        }`
      );
    }
  }

  _addWaitlistInfo(gridItem: HTMLElement, displayDispatchDate: string) {
    this._addStyling();

    const gridItemObserver = new SelectorObserver({
      selector: `${this.sizeSelector}, ${this.buttonSelector}`,
      container: gridItem,
      mode: SelectorObserverMode.ON_SHOWN,
    });

    gridItemObserver.listen((elem) => {
      if (elem.classList.contains('pd-quick-add-waitlist-info-added')) {
        return;
      }

      const displayDispatchDateElem = document.createElement('div');
      displayDispatchDateElem.innerHTML = `${displayDispatchDate}`;

      const noChargeInfo = document.createElement('div');
      noChargeInfo.innerHTML = 'No charge until pre-order ships. ';
      noChargeInfo.appendChild(createLearnMoreButton());

      const waitlistInfo = document.createElement('div');
      waitlistInfo.classList.add('pd-quick-add-waitlist-info');
      waitlistInfo.appendChild(displayDispatchDateElem);
      waitlistInfo.appendChild(noChargeInfo);

      elem.insertAdjacentElement('afterend', waitlistInfo);

      elem.classList.add('pd-quick-add-waitlist-info-added');
    });
  }

  _addStyling() {
    if (document.getElementById('pd-quick-add-styling')) {
      return;
    }

    const styling = document.createElement('style');
    styling.id = 'pd-quick-add-styling';
    styling.innerHTML = `
    .pd-logo:before {
        display: inline-block;
        position: relative;
        content: ' ';
        border-radius: 50%;
        width: 8px;
        height: 8px;
        bottom: 1px;
        background-color: var(--pd-logo-color);
    }

    .pd-quick-add-waitlist-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  `;
    document.head.appendChild(styling);
  }

  _getSoldOutBadges(gridItem: HTMLElement) {
    return (
      getAllChildren(gridItem)
        // textContent is "sold out"
        .filter((element) => {
          if (element instanceof HTMLElement) {
            return (
              element.textContent?.toLowerCase().includes('sold out') ||
              element.textContent?.toLowerCase().includes('out of stock')
            );
          }
          return false;
        })
        // Only has children that don't contain any text
        .filter(
          (element) =>
            Array.from(element.childNodes).filter(
              (child) =>
                child instanceof HTMLElement && child.textContent?.trim() !== ''
            ).length === 0
        )
    );
  }

  _getNotifyMeButtons(gridItem: HTMLElement): HTMLButtonElement[] {
    return getAllChildButtons(gridItem).filter((element) =>
      element.textContent?.toLowerCase().includes('notify me')
    );
  }

  _removeSomeAttributes(gridItem: HTMLElement) {
    this.removeAttributes.forEach(({ selector, attributes }) => {
      const els = gridItem.querySelectorAll(selector);
      if (els) {
        attributes.forEach((attr) =>
          els.forEach((el) => el.removeAttribute(attr))
        );
      }
    });
  }

  _hideElements(gridItem: HTMLElement) {
    if (!this.plpHide || this.plpHide.length === 0) return;

    this.plpHide.forEach((selector) => {
      gridItem.querySelector(selector)?.classList.add('pd-hide');
    });
  }
}

function getAllChildButtons(element: Element): HTMLButtonElement[] {
  const buttonElems = getAllChildren(element).filter(
    (el) => el instanceof HTMLButtonElement
  );

  return buttonElems.filter((el) => el.tagName === 'BUTTON');
}

function getAllChildren(htmlElement: Element): Element[] {
  if (htmlElement.children.length === 0) {
    return [htmlElement];
  }

  const allChildElements: Element[] = [];

  for (const element of htmlElement.children) {
    const children = getAllChildren(element);
    if (children) {
      allChildElements.push(...children);
    }
  }
  allChildElements.push(htmlElement);

  return allChildElements;
}

export { QuickAddFromCollectionItem };
