declare global {
  interface Window {
    Shopify: Partial<{
      shop: string;
      locale: string;
      country: string;
      routes: {
        root: string;
      };
      currency: {
        active: string;
        rate: number;
      };
    }>;
  }
}

export interface ShopifyCart {
  items: ShopifyCartLineItem[];
}

export type ShopifyLineItemProperties = { [key: string]: string };

export interface ShopifyCartLineItem {
  id: number;
  key: string;
  variant_id: number;
  quantity: number;
  properties?: ShopifyLineItemProperties;
  selling_plan_allocation?: {
    selling_plan: {
      id: number;
      name: string;
    };
  };
}
export interface ShopifyProduct {
  id: number;
  handle: string;
  available: boolean;
  variants: ShopifyProductVariant[];
  selling_plan_groups: SellingPlanGroup[] | null;
}

export interface ShopifyProductVariant {
  id: number;
  available: boolean;
  inventory_quantity: number | undefined;
  selling_plan_allocations: SellingPlanAllocation[] | null;
}

export interface SellingPlanGroup {
  id: number;
  name: string;
  selling_plans: SellingPlan[];
}

export interface SellingPlan {
  id: number;
  name: string;
}

export interface SellingPlanAllocation {
  selling_plan_group_id: number;
  selling_plan_id: number;
}

class ShopifyApi {
  async fetchProduct(handle: string): Promise<ShopifyProduct> {
    const root = window.Shopify?.routes?.root || '/';

    const url = `${root}products/${handle}.js`;
    const res = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        charset: 'utf-8',
      },
    });
    const text = await res.text();
    return JSON.parse(text);
  }

  async fetchCart(): Promise<ShopifyCart> {
    const response = await fetch('/cart.js');
    const cart = await response.json();
    return cart;
  }

  async changeCartItem(
    lineItemKey: string,
    update: {
      quantity?: number;
      properties?: { [key: string]: string };
      sellingPlanId?: number | null;
    }
  ) {
    const response = await fetch('/cart/change.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: lineItemKey,
        quantity: update.quantity,
        properties: update.properties,
        selling_plan: update.sellingPlanId,
      }),
    });
    const cart = await response.json();
    return cart;
  }
}

export { ShopifyApi };
