import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/views/get-waitlists-response';
import * as UrlUtils from '../shopify-theme/url-utils';
import { WaitlistAvailability } from '../waitlist-availability';

type OnProductPageWithWaitlistCallback = (
  ctx: {
    urlUtils: typeof UrlUtils;
  },
  waitlist: { waitlist: GetWaitlistsResponseItem }
) => void;

class WaitlistEventForwarder {
  waitlistAvailability!: WaitlistAvailability;
  onProductPageWithWaitlist: OnProductPageWithWaitlistCallback;

  constructor({
    waitlistAvailability,
    onProductPageWithWaitlist,
  }: {
    waitlistAvailability: WaitlistAvailability;
    onProductPageWithWaitlist: OnProductPageWithWaitlistCallback;
  }) {
    this.waitlistAvailability = waitlistAvailability;
    this.onProductPageWithWaitlist = onProductPageWithWaitlist;
  }

  async onProductPageLoaded() {
    if (this.onProductPageWithWaitlist) {
      const handle = UrlUtils.extractHandle(window.location.href);
      if (!handle) {
        return;
      }

      const waitlist = await this.waitlistAvailability.getWaitlist({ handle });
      if (waitlist) {
        this.onProductPageWithWaitlist(
          {
            urlUtils: UrlUtils,
          },
          { waitlist }
        );
      }
    }
  }
}

export { WaitlistEventForwarder };
